import React from 'react'
import {
  Container,
  Section,
  Seo,
  CommonBanner,
} from '@components'
import { PageLayout } from '../../components/site/layout/page-layout'
import { StaticPinningTool } from '../../components/site/static-pinning'
import { usePage } from '../../components/site/offer'

const StaticPinningPage = ({ location }) => {
  // check for initial tab

  const {
    queryParams: { tab },
  } = usePage()

  return (
    <PageLayout pathname={location.pathname}>
      <CommonBanner title="Mobile Certificate Pinning Generator" />

      <Section>
        <Container>
          <StaticPinningTool className="py-[6rem]" initialTab={tab} />
        </Container>
      </Section>
    </PageLayout>
  )
}

export default StaticPinningPage
